import { ICodeName } from '@/common/interfaces/code-name.interface'
import { IFormItem } from '@/common/interfaces/form-item.interface'
import { IIdName } from '@/common/interfaces/id-name.interface'

import { InputTypes } from './form-control.constants'

export const DynamicFormSelectItems = [
  { item_1: 'Item 1' },
  { item_2: 'Item 2' },
  { item_3: 'Item 3' },
  { item_4: 'Item 4' },
  { item_5: 'Item 5' },
  { item_6: 'Item 6' },
]

export const OptionsCategory = ['Product option', 'Product service', 'Inventory']

export const YesNoItems = [
  { key: 1, value: 'Yes' },
  { key: 0, value: 'No' },
]

export const YesNo = [
  { value: 1, name: 'Yes' },
  { value: 0, name: 'No' },
]

export const HardwareCategory = ['individual', 'combined']

export const SiteDetailsFields: IFormItem[] = [
  { label: 'Site name', name: 'title', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Address', name: 'address', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Postal Code', name: 'postalCode', type: InputTypes.TEXT, control: 'input', customClassName: 'smallItem' },
  { label: 'City', name: 'city', type: InputTypes.TEXT, control: 'input', customClassName: 'smallItem' },
  { label: 'Country/Region', name: 'country', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Country', name: 'country', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Phone', name: 'phone', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Time Zone', name: 'timezone', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Wi-fi name', name: 'wifiname', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Site Description',
    name: 'description',
    type: 'textarea',
    control: 'input',
    customClassName: 'extraLargeItem',
  },
]

export const HardwareDetailsFields: IFormItem[] = [
  { label: 'Hardware type', name: 'type', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Hardware name', name: 'name', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Hardware ID', name: 'hardwareId', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
]

export const RoomDetailsFields: IFormItem[] = [
  { label: 'Room type', name: 'type', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Name', name: 'name', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Location/Floor',
    name: 'location-floor',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  { label: 'Size', name: 'size', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Room ID', name: 'id', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Room Description',
    name: 'description',
    type: 'textarea',
    control: 'input',
    customClassName: 'extraLargeItem',
  },
]

const usaTimeZones = [
  'America/Adak',
  'America/Anchorage',
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Los_Angeles',
  'America/Menominee',
  'America/Metlakatla',
  'America/New_York',
  'America/Nome',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Sitka',
  'America/Yakutat',
  'Europe/London',
  'Europe/Bucharest',
]

export const timeZone = usaTimeZones.map((tz) => ({
  value: tz,
  label: tz,
}))

export const Status: ICodeName[] = [
  {
    code: 0,
    name: 'Offline',
  },
  {
    code: 1,
    name: 'Online',
  },
]
export const Minutes: ICodeName[] = [
  {
    code: 5,
    name: '5min',
  },
  {
    code: 10,
    name: '10min',
  },
  {
    code: 15,
    name: '15min',
  },
  {
    code: 20,
    name: '20min',
  },
  {
    code: 25,
    name: '25min',
  },
  {
    code: 30,
    name: '30min',
  },
]

export const Included: IIdName[] = [
  { id: 1, name: 'Desk' },
  { id: 2, name: 'Chair' },
]

export const dataSource = [
  {
    key: '1',
    hardware: 'KNX Gateway',
    connectionInfo: 'Hdmi Port 2',
  },
  {
    key: '2',
    hardware: 'Mouse',
    connectionInfo: 'Hdmi',
  },
]

export const InventoryDetailsFields: IFormItem[] = [
  { label: 'Type', name: 'type', type: 'select', control: 'input', customClassName: 'largeItem' },
  { label: 'Product Name', name: 'productName', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Room', name: 'room', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Price', name: 'price', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
]

export const InventoryDataFields: IFormItem[] = [
  {
    label: 'Quantity of hand',
    name: 'quantityOfHand',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  { label: 'UPC', name: 'upc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'NPC', name: 'npc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'VPC', name: 'vpc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Change value', name: 'changeValue', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Recorder level',
    name: 'recorderLevel',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Recorder quantity',
    name: 'recorderQuantity',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Control number',
    name: 'controlNumber',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Limited item number',
    name: 'limitedItemNumber',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  { label: 'Ticket ID', name: 'ticketId', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
]

export const ProductServicesDetailsFields: IFormItem[] = [
  { label: 'Type', name: 'type', type: 'select', control: 'select', customClassName: 'largeItem' },
  {
    label: 'Product Service Name',
    name: 'productServiceName',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  { label: 'Room', name: 'room', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Price', name: 'price', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
]

export const ProductServicesDataFields: IFormItem[] = [
  { label: 'UPC', name: 'upc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'NPC', name: 'npc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'VPC', name: 'vpc', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Change value', name: 'changeValue', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Recorder level',
    name: 'recorderLevel',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Recorder quantity',
    name: 'recorderQuantity',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Control number',
    name: 'controlNumber',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  {
    label: 'Limited item number',
    name: 'limitedItemNumber',
    type: InputTypes.TEXT,
    control: 'input',
    customClassName: 'largeItem',
  },
  { label: 'Ticket ID', name: 'ticketId', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
]

export const ServicesTypes: any = [
  {
    id: 1,
    name: 'Type 1',
  },
  {
    id: 2,
    name: 'Type 2',
  },
  {
    id: 3,
    name: 'Type 3',
  },
  {
    id: 4,
    name: 'Type 4',
  },
  {
    id: 5,
    name: 'Type 5',
  },
]

export const RoomsTypeFields: IFormItem[] = [
  { label: 'Room Type', name: 'roomType', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Visible for marketing',
    name: 'visibleForMarketing',
    type: 'select',
    control: 'select',
    customClassName: 'largeItem',
  },
  { label: 'Price', name: 'price', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Description', name: 'description', type: 'textarea', control: 'input', customClassName: 'extraLargeItem' },
]

export const OptionsFields: IFormItem[] = [
  { label: 'Option Type', name: 'type', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Category', name: 'category', type: 'select', control: 'select', customClassName: 'largeItem' },
  { label: 'Price', name: 'price', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  { label: 'Description', name: 'description', type: 'textarea', control: 'input', customClassName: 'extraLargeItem' },
]

export const HardwareFields: IFormItem[] = [
  { label: 'Name', name: 'name', type: InputTypes.TEXT, control: 'input', customClassName: 'largeItem' },
  {
    label: 'Hardware category',
    name: 'category',
    type: InputTypes.TEXT,
    control: 'select',
    customClassName: 'largeItem',
  },
]

export const HardwareOptionsFields: IFormItem[] = [
  { label: 'Price', name: 'price', type: InputTypes.NUMBER, control: 'input', customClassName: 'largeItem' },
]

export const InputTypesList = ['text', 'select']

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
